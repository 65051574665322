import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import {
    Container,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Spinner,
} from "reactstrap";
import {} from "reactstrap";
import {
    getAllYears,
    getPerformanceRecords,
} from "../store/actions/PerformanceAction";
const initialState = {
    options: {
        chart: {
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            categories: [],
        },
    },
    series: [
        {
            name: "series",
            data: [],
        },
    ],
};

const PerformanceChart = () => {
    const dispatch = useDispatch();
    const {
        performanceRecords,
        performanceLoading,
        factorsData,
        factorsLoading,
        allYearsData,
    } = useSelector((state) => state.performance);
    const [chartState, setChartState] = useState(initialState);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const [year, setYear] = useState(new Date().getFullYear().toString());

    useEffect(() => {
        if (performanceRecords?.length > 0) {
            const sortedRecords = performanceRecords.sort((a, b) => {
                const monthsOrder = [
                    "jan",
                    "feb",
                    "mar",
                    "apr",
                    "may",
                    "jun",
                    "jul",
                    "aug",
                    "sep",
                    "oct",
                    "nov",
                    "dec",
                ];
                return (
                    monthsOrder.indexOf(a.month.toLowerCase()) -
                    monthsOrder.indexOf(b.month.toLowerCase())
                );
            });
            const uniqueMonths = [
                ...new Set(
                    sortedRecords.map((record) => record.month.toLowerCase())
                ),
            ];

            const seriesData = Array.from(
                { length: uniqueMonths.length },
                () => 0
            );

            sortedRecords.forEach((record) => {
                const index = uniqueMonths.indexOf(record.month.toLowerCase());
                seriesData[index] = record.value;
            });
            setChartState((prevState) => ({
                options: {
                    ...prevState.options,
                    xaxis: {
                        categories: uniqueMonths.map(
                            (month) =>
                                month.charAt(0).toUpperCase() + month.slice(1)
                        ),
                    },
                },
                series: [
                    {
                        name: "ROI",
                        data: seriesData,
                    },
                ],
            }));
        } else {
            setChartState(initialState);
        }
    }, [performanceRecords]);

    useEffect(() => {
        dispatch(getAllYears());
    }, []);
    useEffect(() => {
        dispatch(getPerformanceRecords(year));
    }, [year]);

    return (
        <>
            <div className="d-flex justify-content-center">
                <div className=" px-auto chart__div">
                    <Row className="justify-content-end mt-4">
                        <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle}
                            direction="down"
                        >
                            <DropdownToggle caret color="primary">
                                {year}
                            </DropdownToggle>
                            <DropdownMenu>
                                {allYearsData?.map((item) => {
                                    return (
                                        <DropdownItem
                                            onClick={() => setYear(item)}
                                        >
                                            {item}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </Row>
                    {performanceLoading ? (
                        <Row className="justify-content-center my-4">
                            <Spinner />
                        </Row>
                    ) : (
                        <>
                            {chartState !== null && (
                                <Chart
                                    options={chartState.options}
                                    series={chartState.series}
                                    type="line"
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
            <Container>
                {factorsLoading ? (
                    <Row className="d-flex flex-column align-items-center justify-content-center mx-3 py-4">
                        <Spinner />
                    </Row>
                ) : (
                    <Row className="d-flex flex-column align-items-start justify-content-center mx-3 py-4">
                        {factorsData?.length > 0 ? (
                            factorsData?.map((item) => {
                                return (
                                    <div className="my-3">
                                        <p className="text-capitalize">
                                            <strong>{item.title}</strong>
                                        </p>
                                        <p>{item.description}</p>
                                    </div>
                                );
                            })
                        ) : (
                            <>
                                <p>No Data available</p>
                            </>
                        )}
                    </Row>
                )}
            </Container>
        </>
    );
};

export default PerformanceChart;
