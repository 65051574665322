export const servicesData = [
    {
        title: "Quantitative Hedge Fund",
        content:
            "At 238k, our quantitative hedge funds leverage advanced analytics to drive informed investment decisions, delivering consistent alpha and portfolio diversification for our clients.",
        btnLink: "/contact",
    },
    {
        title: "Private Investment Funds",
        content:
            "Discover exclusive investment opportunities with 238k's tailored private investment funds, designed to deliver superior returns across venture capital, real estate, and private equity sectors.",
        btnLink: "/contact",
    },
    {
        title: "Financial & Management Advisory",
        content:
            "238k offers personalized financial and management advisory services, guiding clients through strategic decision-making and optimizing resources for long-term success.",
        btnLink: "/contact",
    },
];

export const newOpportunitiesData = [
    {
        sectionTitle: "New Opportunities",
        description:
            "Introducing Exciting Investment Avenues. In this section, we present new investment opportunities and funds that offer potential for growth and diversification. Explore these promising ventures to enhance your portfolio and capitalize on emerging trends.",
        featuredFunds: [
            {
                title: "Tech Innovators Fund",
                content:
                    "Invest in cutting-edge technology companies driving innovation across various sectors. This fund focuses on disruptive technologies such as artificial intelligence, cloud computing, and biotech, offering potential for substantial returns.",
                btnLink: "/contact",
            },
            {
                title: "Renewable Energy Fund",
                content:
                    "Embrace sustainability and invest in renewable energy sources like solar, wind, and hydroelectric power. This fund targets companies leading the transition to clean energy solutions, providing opportunities for both environmental impact and financial growth.",
                btnLink: "/contact",
            },
            {
                title: "Global Healthcare Fund",
                content:
                    "Gain exposure to the booming healthcare industry worldwide. This fund invests in pharmaceuticals, biotechnology, medical devices, and healthcare services, capitalizing on demographic shifts, technological advancements, and increasing healthcare spending.",
                btnLink: "/contact",
            },
        ],
    },
];

export const testimonialsData = [
    {
        heading: "WE INVEST IN GROWTH",
        content:
            "We provide our people with the resources to build expertise, innovate in their fields, and develop professionally, ensuring they forge enduring careers and lead the industry. Additionally, we invest in local talent and early startups in Dubai, helping them grow into global brands.",
        btnText: "Contact Us",
    },
    {
        heading: "WE VALUE SUSTAINABILITY AND COMMUNITY",
        content:
            "We prioritize sustainability in our operations, encourage eco-friendly practices, and support community initiatives. Our commitment to sustainability fosters a culture of environmental responsibility and community engagement.",
        btnText: "Contact Us",
    },
    {
        heading: "WE THRIVE ON NEW CHALLENGES",
        content:
            "We are constantly seeking opportunities to grow and integrate new expertise into our firm. Our expansion spans across various geographies, strategies, and asset classes as we pursue durable, diversified returns.",
        btnText: "Contact Us",
    },
];

export const aboutUsData = [
    {
        heading: "238k Investments",
        content:
            "is a distinguished holding company renowned for its expertise in investment management and consulting services.Founded in 2020, we are dedicated to delivering comprehensive solutions that foster financial success and operational excellence.",
    },
    {
        heading: "Strategic Investment Solutions: ",
        content:
            "At the core of our operations is our quantitative hedge fund, utilizing advanced analytics and proprietary algorithms to uncover market opportunities. Our investment approach integrates meticulous quantitative research with practical market insights, aiming to consistently outperform and provide robust returns.",
    },
    {
        heading: "Private Investment Opportunities: ",
        content:
            "We also manage a suite of private investment funds tailored for a diverse clientele including institutional investors, family offices, and affluent individuals. These funds are strategically invested across multiple sectors such as technology, real estate, and emerging markets, focusing on long-term capital growth and portfolio diversification",
    },
    {
        heading: "Advisory and Consulting Services: ",
        content:
            "Our advisory team offers expert financial guidance and strategic planning, helping clients navigate complex financial environments. Additionally, our management consulting services extend beyond financial advice into comprehensive business solutions. We assist organizations in enhancing their operational efficiencies, driving growth, and implementing effective change management strategies. Our consultants are adept at aligning financial goals with broader business objectives, ensuring holistic development and sustainable success",
    },
    {
        heading: "At 238K,",
        content:
            "we are committed to the highest standards of integrity and excellence. Our mission is to empower our clients by safeguarding their assets, optimizing their operations, and facilitating enduring growth through innovative and customized strategies. We pride ourselves on our client centric approach, ensuring that each client's unique needs and ambitions are at the forefront of our efforts.",
        btnText: "view our values",
    },
];
