import React from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Nav, NavItem, Navbar, NavbarBrand } from "reactstrap";
import logoBlack from "../assets/img/svgs/logoBlack.svg";

const Sidebar = ({ toggleMenu }) => {
    const history = useHistory();
    const links = [
        {
            name: "About us",
            value: "/about-us",
        },
        {
            name: "Services",
            value: "/services",
        },
        {
            name: "Performance & Insights",
            value: "/performance",
        },
        {
            name: "Contact Us",
            value: "/contact",
        },
    ];
    return (
        <div className="sidebar">
            <Navbar
                color="transparent"
                dark
                expand="md"
                className="d-flex justify-content-between"
            >
                <NavbarBrand>
                    <img
                        alt="logo"
                        src={logoBlack}
                        onClick={() => history.push("/")}
                    />
                </NavbarBrand>
                <button
                    className="o-button-hamburger js-mobile-menu u-hide-after--m is-active"
                    onClick={toggleMenu}
                >
                    <label>Menu</label>
                    <div className="lines">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </div>
                </button>
            </Navbar>
            <Nav className="d-flex justify-content-start flex-column">
                {links?.map((item) => {
                    return (
                        <NavItem onClick={() => history.push(item.value)}>
                            <Link to={item.value} className="px-0">
                                {item.name}
                            </Link>
                        </NavItem>
                    );
                })}
            </Nav>
        </div>
    );
};

export default Sidebar;
