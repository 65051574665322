import React from "react";
import ComponentHeader from "../components/ComponentHeader";
import ServicesCard from "../components/Services/ServicesCard";
import { Col, Container, Row } from "reactstrap";
import { servicesData } from "../DummyData/Common";

const Services = () => {
    return (
        <>
            {" "}
            <ComponentHeader
                title={"Our Services"}
                subTitle={
                    "Explore our comprehensive suite of investment and consulting services designed to meet your diverse needs."
                }
            />
            <article className="o-hero-internal o-background-cover u-background--bluegray-1">
                <Container className="py-5">
                    <Row className="my-4 mx-auto justify-content-center">
                        {servicesData?.map((item) => {
                            return (
                                <Col
                                    xs={12}
                                    sm={8}
                                    md={6}
                                    lg={4}
                                    className="my-4 d-flex justify-content-center"
                                    key={item.title}
                                >
                                    <ServicesCard item={item} />
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </article>
        </>
    );
};

export default Services;
