// Root Reducer

import { combineReducers } from "redux";
import performanceReducer from "./PerformanceReducers";

export let rootReducer = combineReducers({
    performance: performanceReducer,
});

export default rootReducer;
