import React from "react";
import { Col, Container, Row } from "reactstrap";
import { aboutUsData } from "../DummyData/Common";
import ComponentHeader from "../components/ComponentHeader";

const About = () => {
    return (
        <>
            <ComponentHeader title={"About Us"} />
            <article className="o-section o-background-cover u-background--light">
                <Container className="about text-dark py-5">
                    {aboutUsData?.map((item, index) => {
                        return (
                            <Row
                                className={`d-flex align-items-center py-5 ${
                                    index % 2 == 1 ? "flex-row-reverse" : ""
                                }`}
                                key={index}
                            >
                                {/* <Col
                                    md="12"
                                    lg="6"
                                    xl="6"
                                    className={`d-flex align-items-center justify-content-center  ${
                                        index % 2 == 0 ? "flex-row-reverse" : ""
                                    }`}
                                >
                                    <img
                                        className={`image1 w-75 rounded  ${
                                            index % 2 == 0 ? "ml-4" : "mr-4"
                                        }`}
                                        src={item?.majorImg}
                                        alt=""
                                    />
                                </Col> */}
                                <Col
                                    md="12"
                                    lg="6"
                                    xl="6"
                                    className="mt-4 mt-lg-0"
                                >
                                    <p
                                        data-aos={`${
                                            index % 2 == 0
                                                ? "fade-left"
                                                : "fade-right"
                                        }`}
                                    >
                                        <strong className="regular">
                                            {item.heading}{" "}
                                        </strong>
                                        {item?.content}
                                    </p>
                                </Col>
                            </Row>
                        );
                    })}
                </Container>
            </article>
        </>
    );
};

export default About;
