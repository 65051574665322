import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { sendFormData } from "../store/actions/PerformanceAction";
import { useDispatch, useSelector } from "react-redux";

const ContactForm = () => {
    const { performanceLoading } = useSelector((state) => state.performance);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "",
        company: "",
        email: "",
        phone: "",
        reason: "investment",
        message: "",
        captcha: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData?.captcha == "5") {
            delete formData?.captcha;
            dispatch(
                sendFormData(formData, () => {
                    toast.success("Form submitted successfully");
                    setFormData({
                        name: "",
                        company: "",
                        email: "",
                        phone: "",
                        reason: "investment",
                        message: "",
                        captcha: "",
                    });
                })
            );
        } else {
            toast.error("Captcha verification failed");
        }
    };
    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="name">Name *</Label>
                <Input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="company">Company (optional)</Label>
                <Input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for="email">Email *</Label>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="phone">Phone (optional)</Label>
                <Input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reason">Reason for Contact *</Label>
                <Input
                    type="select"
                    id="reason"
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    required
                >
                    <option value="investment">Investment</option>
                    <option value="other">Other</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="message">Message *</Label>
                <Input
                    type="textarea"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    rows={12}
                />
            </FormGroup>
            <FormGroup>
                <Label for="captcha">What is the sum of 2 and 3? *</Label>
                <Input
                    type="text"
                    id="captcha"
                    name="captcha"
                    className="w-25"
                    value={formData.captcha}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <Button
                color="success"
                type="submit"
                className="contact_btn"
                disabled={performanceLoading}
            >
                {performanceLoading ? (
                    <Spinner size="sm" className="mx-4" />
                ) : (
                    "Submit"
                )}
            </Button>
        </Form>
    );
};

export default ContactForm;
