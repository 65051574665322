import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ComponentHeader from "../components/ComponentHeader";
import NewsFeed from "../components/NewsFeed";
import Oppurtunities from "../components/Oppurtunities";
import PerformanceChart from "../components/PerformanceChart";
import {
    getArticlesAction,
    getKeyFactorsAction,
} from "../store/actions/PerformanceAction";

const PerformanceInsights = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getArticlesAction());
        dispatch(getKeyFactorsAction());
    }, []);

    return (
        <article className="o-hero-internal o-background-cover u-background--bluegray-1 pb-3 pt-0 ">
            <ComponentHeader title={"Performance & Insights"} />
            <PerformanceChart />
            <Oppurtunities />
            <NewsFeed />
        </article>
    );
};

export default PerformanceInsights;
