import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDnP_kIjhwFY5T_Mc5hrbEoU6d5-mMrY8U",
    authDomain: "kinvestment-a6fa9.firebaseapp.com",
    projectId: "kinvestment-a6fa9",
    storageBucket: "kinvestment-a6fa9.appspot.com",
    messagingSenderId: "298442420071",
    appId: "1:298442420071:web:e44831e7c98ad2774bc9c2",
    measurementId: "G-0P2T8V3GC6",
};
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
export default firebase;
