import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Nav, NavItem, Navbar, NavbarBrand } from "reactstrap";
import logoYellow from "../assets/img/svgs/logoYellow.svg";

const NavBar = ({ toggleMenu }) => {
    const links = [
        {
            name: "About us",
            value: "/about-us",
        },
        {
            name: "Services",
            value: "/services",
        },
        {
            name: "Performance & Insights",
            value: "/performance",
        },
        {
            name: "Contact Us",
            value: "/contact",
        },
    ];

    return (
        <Navbar
            color="transparent"
            dark
            expand="md"
            className="d-flex justify-content-between main_navbar"
        >
            <NavbarBrand>
                <Link to="/">
                    {" "}
                    <img alt="logo" src={logoYellow} />
                </Link>
            </NavbarBrand>
            <Nav className="d-flex justify-content-center navigation">
                {links?.map((item) => {
                    return (
                        <NavItem className="mx-2">
                            <Link to={item.value}>{item.name}</Link>
                        </NavItem>
                    );
                })}
            </Nav>

            <div></div>
            {/* <div className="navbar_btn">
                <a
                    href="https://careers.point72.com/"
                    id=""
                    className="o-button u-scroll-animation o-button--primary-ghost u-scroll-animate"
                    target="_self"
                >
                    Browse Open Roles
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </a>
            </div> */}
            <button
                className="o-button-hamburger js-mobile-menu u-hide-after--m"
                onClick={toggleMenu}
            >
                <label>Menu</label>
                <div className="lines">
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            </button>
        </Navbar>
    );
};

export default NavBar;
