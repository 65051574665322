import React from "react";
import { Col, Row } from "reactstrap";
import ComponentHeader from "../components/ComponentHeader";
import ContactForm from "../components/ContactForm";

const ContactUs = () => {
    return (
        <>
            <ComponentHeader
                title={"Contact Us"}
                subTitle={
                    "Our executive team applies their diverse perspectives and deep expertise to advancing our business and developing our people."
                }
            />
            <article className="o-section o-background-cover u-background--light">
                <section className="o-block u-spacing--quad--top u-spacing--quad--bottom">
                    <div className="l-container l-wrap">
                        <p>For all inquiries, please submit the form below.</p>
                    </div>
                    <Row className="mx-3 my-4">
                        <Col xs={10} sm={8} md={6} lg={4} className="ml-4">
                            <ContactForm />
                        </Col>
                    </Row>
                </section>
            </article>
        </>
    );
};

export default ContactUs;
