import React from "react";
import ComponentHeader from "../components/ComponentHeader";

function TermsofUse() {
    return (
        <>
            <ComponentHeader title={"Terms of Use"} />
            <article className="o-section o-background-cover u-background--bluegray-1 py-5">
                <section className="o-block u-spacing--quad--top u-spacing--quad--bottom">
                    <div className="l-container l-container--m l-wrap">
                        <div className="o-rte-text">
                            <h1>Terms of Use</h1>
                            <p>
                                Welcome to{" "}
                                <a href="http://238kinvestments.com">
                                    238kinvestments.com
                                </a>
                                . Please read these Terms of Use ("Terms")
                                carefully before using our website. By accessing
                                or using our site, you agree to be bound by
                                these Terms. If you do not agree to these Terms,
                                please do not use our website.
                            </p>
                            <h2>1. Acceptance of Terms</h2>
                            <p>
                                By accessing and using the website owned by
                                238kinvestments ("Company," "we," "us," "our"),
                                you accept and agree to be bound by these Terms
                                of Use and our Privacy Policy. These Terms apply
                                to all visitors, users, and others who access or
                                use the site.
                            </p>
                            <h2>2. Intellectual Property</h2>
                            <h3>2.1 Copyright</h3>
                            <p>
                                All content, features, and functionality on this
                                site, including but not limited to text,
                                graphics, logos, icons, images, audio clips,
                                video clips, data compilations, and software,
                                are the property of 238kinvestments or its
                                content suppliers and are protected by United
                                States and international copyright laws.
                                Unauthorized use or duplication of any materials
                                on this site is prohibited.
                            </p>
                            <h3>2.2 Trademarks</h3>
                            <p>
                                The trademarks, service marks, and logos used
                                and displayed on this site are registered and
                                unregistered trademarks of 238kinvestments and
                                others. Nothing on this site should be construed
                                as granting, by implication, estoppel, or
                                otherwise, any license or right to use any
                                trademark displayed on the site without the
                                prior written permission of the trademark owner.
                            </p>
                            <h3>2.3 Patents</h3>
                            <p>
                                This site and its content may be covered by one
                                or more patents and pending patent applications.
                                The patents protect the design, functionality,
                                and content of our site and services.
                            </p>
                            <h2>3. Use of the Site</h2>
                            <h3>3.1 Permitted Use</h3>
                            <p>
                                You are granted a limited, non-exclusive,
                                non-transferable, and revocable license to
                                access and use the site strictly in accordance
                                with these Terms. You agree not to use the site
                                for any purpose that is unlawful or prohibited
                                by these Terms.
                            </p>
                            <h3>3.2 Prohibited Use</h3>
                            <p>
                                You agree not to use the site in any manner that
                                could damage, disable, overburden, or impair the
                                site or interfere with any other party’s use and
                                enjoyment of the site. Prohibited uses include,
                                but are not limited to:
                            </p>
                            <ul>
                                <li>
                                    Engaging in any activities that infringe on
                                    the intellectual property rights of others.
                                </li>
                                <li>
                                    Uploading or transmitting viruses or other
                                    harmful code.
                                </li>
                                <li>
                                    Using any automated system, such as robots
                                    or spiders, to access the site.
                                </li>
                                <li>
                                    Attempting to gain unauthorized access to
                                    any portion or feature of the site or any
                                    other systems or networks connected to the
                                    site.
                                </li>
                            </ul>
                            <h2>4. User Content</h2>
                            <h3>4.1 Submissions</h3>
                            <p>
                                Any content you submit to the site, including
                                but not limited to comments, feedback,
                                suggestions, ideas, and other submissions
                                (collectively, "User Content"), will be treated
                                as non-confidential and non-proprietary. By
                                providing User Content, you grant
                                238kinvestments a non-exclusive, royalty-free,
                                perpetual, irrevocable, and fully sublicensable
                                right to use, reproduce, modify, adapt, publish,
                                translate, create derivative works from,
                                distribute, and display such User Content
                                throughout the world in any media.
                            </p>
                            <h3>4.2 Responsibility for User Content</h3>
                            <p>
                                You are solely responsible for your User Content
                                and the consequences of submitting and
                                publishing your User Content on the site. By
                                submitting User Content, you affirm, represent,
                                and warrant that you own or have the necessary
                                licenses, rights, consents, and permissions to
                                use and authorize 238kinvestments to use all
                                patent, trademark, trade secret, copyright, or
                                other proprietary rights in and to any and all
                                User Content.
                            </p>
                            <h2>5. Disclaimers and Limitation of Liability</h2>
                            <h3>5.1 Disclaimer of Warranties</h3>
                            <p>
                                The site is provided on an "as is" and "as
                                available" basis. 238kinvestments makes no
                                representations or warranties of any kind,
                                express or implied, as to the operation of the
                                site, the information, content, materials, or
                                products included on the site. You expressly
                                agree that your use of the site is at your sole
                                risk.
                            </p>
                            <h3>5.2 Limitation of Liability</h3>
                            <p>
                                In no event shall 238kinvestments, its
                                directors, officers, employees, or agents be
                                liable for any direct, indirect, incidental,
                                special, or consequential damages arising out of
                                or in connection with your use of the site,
                                whether based on warranty, contract, tort, or
                                any other legal theory, and whether or not
                                238kinvestments is advised of the possibility of
                                such damages.
                            </p>
                            <h2>6. Indemnification</h2>
                            <p>
                                You agree to indemnify, defend, and hold
                                harmless 238kinvestments and its affiliates,
                                officers, directors, employees, and agents from
                                and against any and all claims, liabilities,
                                damages, losses, costs, expenses, or fees
                                (including reasonable attorneys' fees) that they
                                may incur as a result of or arising from your
                                violation of these Terms or your use of the
                                site.
                            </p>
                            <h2>7. Changes to Terms</h2>
                            <p>
                                238kinvestments reserves the right to modify
                                these Terms at any time. Any changes will be
                                effective immediately upon posting on the site.
                                Your continued use of the site after any such
                                changes constitutes your acceptance of the new
                                Terms.
                            </p>
                            <h2>8. Governing Law</h2>
                            <p>
                                These Terms shall be governed by and construed
                                in accordance with the laws of Dubai, UAE,
                                without regard to its conflict of law
                                principles. You agree to submit to the personal
                                jurisdiction of the state and federal courts
                                located in Dubai for the resolution of any
                                disputes arising out of or relating to these
                                Terms or the site.
                            </p>
                        </div>
                    </div>
                </section>
            </article>
        </>
    );
}

export default TermsofUse;
