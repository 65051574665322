import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Row, Spinner } from "reactstrap";

const NewsFeed = () => {
    const { articlesData, articlesLoading } = useSelector(
        (state) => state.performance
    );
    return (
        <div className="my-3 mt-5">
            <h3 className="text-uppercase pt-5 text-center">
                <strong className="regular">News & Articles</strong>
            </h3>
            <section className="o-block u-spacing--quad--top u-spacing--quad--bottom">
                <div className="l-container l-wrap">
                    <ul className="o-list--table u-spacing--triple--top">
                        {articlesLoading ? (
                            <Row className="justify-content-center">
                                <Spinner />
                            </Row>
                        ) : (
                            <>
                                {articlesData?.length > 0 ? (
                                    <>
                                        {articlesData?.map((article, index) => (
                                            <li
                                                key={index}
                                                className="has-animate-hover"
                                            >
                                                <Link
                                                    to={`/article/${article.id}`}
                                                    className="u-padding--top u-padding--bottom"
                                                    bp="grid vertical-center"
                                                >
                                                    <span
                                                        bp="4@md"
                                                        className="o-listing--table__left"
                                                    >
                                                        <img
                                                            src={
                                                                article.thumbnail
                                                            }
                                                            alt=""
                                                            className="thumbnail"
                                                        />
                                                    </span>
                                                    <span
                                                        className="o-listing--table__left"
                                                        bp="4@md"
                                                    >
                                                        <h3 className="u-font regular u-font--l u-font-weight--bold u-line-height--140 u-color-primary">
                                                            {article.title}
                                                        </h3>
                                                    </span>
                                                    <span
                                                        className="o-listing--table__right u-align-right u-align-right--responsive-m"
                                                        bp="2@md"
                                                    >
                                                        <span className="o-button--text o-button--text-sm">
                                                            Read More
                                                            <span className="o-icon">
                                                                <svg
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 25 7"
                                                                >
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="m25 3-4-3-.6.8L23.3334 3H9v1h14.3333L20.4 6.2l.6.8 4-3V3ZM1 2.5c.5523 0 1 .4477 1 1s-.4477 1-1 1-1-.4477-1-1 .4477-1 1-1Zm4 0c.5523 0 1 .4477 1 1s-.4477 1-1 1-1-.4477-1-1 .4477-1 1-1Z"
                                                                        fill="#000000"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </Link>
                                            </li>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <p>No Data available</p>
                                    </>
                                )}
                            </>
                        )}
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default NewsFeed;
