import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Input, InputGroup, Row } from "reactstrap";
import logoYellow from "../assets//img/svgs/logoYellow.svg";
import { subscribeNewsLetter } from "../store/actions/PerformanceAction";
import { toast } from "react-toastify";

const Footer = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");

    const footerLinks = [
        {
            section: "About",
            links: [
                {
                    option: "About Us",
                    value: "/about-us",
                },
            ],
        },
        {
            section: "Join our team",
            links: [
                {
                    option: "Services",
                    value: "/services",
                },
                {
                    option: "Performance & Insights",
                    value: "/performance",
                },
                {
                    option: "Contact Us",
                    value: "/contact",
                },
            ],
        },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            subscribeNewsLetter(email, () => {
                setEmail("");
                toast.success("Subscibed Sucessfully");
            })
        );
    };

    return (
        <div className="footer-container pt-5">
            <div className="l-container l-wrap">
                <Row className="mb-5">
                    <Col xs={12} lg={2}>
                        <Link to="/">
                            <img
                                src={logoYellow}
                                alt=""
                                className="footer_img"
                            />
                        </Link>
                    </Col>
                    <Col
                        xs={12}
                        lg={6}
                        className="mt-5 mt-lg-0 d-flex flex-column flex-lg-row justify-content-around align-items-start"
                    >
                        {footerLinks?.map((item, index) => {
                            return (
                                <>
                                    <li
                                        key={index}
                                        className=" mb-3 mt-lg-0 c-menu-footer__menu-item c-menu-footer__menu-item-type-custom c-menu-footer__menu-item-object-custom c-menu-footer__menu-item-9004111222156023 c-menu-footer__menu-item-has-children"
                                    >
                                        <span className="o-heading--s u-text-transform--upper u-display--block u-color--white u-spacing--half--bottom">
                                            {item.section}
                                        </span>
                                        <ul className="c-menu-footer--sub-menu">
                                            {item.links?.map((link, idx) => {
                                                return (
                                                    <>
                                                        <li
                                                            key={idx}
                                                            className="u-font--m  c-menu-footer--sub-menu__menu-item c-menu-footer--sub-menu__menu-item-type-post_type c-menu-footer--sub-menu__menu-item-object-page c-menu-footer--sub-menu__menu-item-9004111222156024"
                                                        >
                                                            <Link
                                                                className="cursor-pointer"
                                                                target=""
                                                                to={link.value}
                                                            >
                                                                {link.option}
                                                            </Link>
                                                        </li>
                                                    </>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                </>
                            );
                        })}
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        md={8}
                        lg={4}
                        className="mt-5  mt-lg-0 d-flex flex-column flex-lg-row justify-content-around align-items-start"
                    >
                        <div className="w-100 mb-3 mt-lg-0 c-menu-footer__menu-item c-menu-footer__menu-item-type-custom c-menu-footer__menu-item-object-custom c-menu-footer__menu-item-9004111222156023 c-menu-footer__menu-item-has-children">
                            <span className="o-heading--s u-text-transform--upper u-display--block u-color--white u-spacing--half--bottom">
                                Subscribe To NewsLetter
                            </span>
                            <InputGroup className="align-items-center news_letter mt-4">
                                <Input
                                    className="py-2"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                />
                                <Link
                                    to="#"
                                    className="o-button u-scroll-animation o-button--primary u-scroll-animate "
                                    onClick={(e) => {
                                        if (email) {
                                            handleSubmit(e);
                                        } else {
                                            toast.error(
                                                "Please Enter your Email Address"
                                            );
                                        }
                                    }}
                                >
                                    Subscribe
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </Link>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
                <hr className="u-spacing--and-half--bottom u-spacing--and-half--top" />
                <div className="c-main-footer__utility pb-4">
                    <nav className="c-menu-utility">
                        <ul>
                            <li>© 2024 238k, L.P.</li>
                            <li>All Rights Reserved</li>
                            <li className=" c-menu-utility__menu-item c-menu-utility__menu-item-type-post_type c-menu-utility__menu-item-object-page c-menu-utility__menu-item-privacy-policy c-menu-utility__menu-item-9004111222156425">
                                <Link
                                    className=""
                                    target=""
                                    to="/global-privacy-policy"
                                >
                                    Global Privacy Policy
                                </Link>
                            </li>
                            <li className=" c-menu-utility__menu-item c-menu-utility__menu-item-type-post_type c-menu-utility__menu-item-object-page c-menu-utility__menu-item-9004111222156421">
                                <Link className="" target="" to="/terms-of-use">
                                    Terms of Use
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Footer;
