import About from "../views/About";
import ContactUs from "../views/ContactUs";
import GlobalPrivacyPolicy from "../views/GlobalPrivacyPolicy";
import MainView from "../views/MainView";
import PerformanceInsights from "../views/PerformanceInsights";
import Services from "../views/Services";
import SingleArticle from "../views/SingleArticle";
import TermsofUse from "../views/TermsofUse";

let routes = [
    {
        path: "/",
        component: MainView,
        layout: "main",
    },
    {
        path: "/about-us",
        component: About,
        layout: "main",
    },
    {
        path: "/article/:id",
        component: SingleArticle,
        layout: "main",
    },
    {
        path: "/terms-of-use",
        component: TermsofUse,
        layout: "main",
    },
    {
        path: "/contact",
        component: ContactUs,
        layout: "main",
    },
    {
        path: "/performance",
        component: PerformanceInsights,
        layout: "main",
    },
    {
        path: "/services",
        component: Services,
        layout: "main",
    },
    {
        path: "/global-privacy-policy",
        component: GlobalPrivacyPolicy,
        layout: "main",
    },
];
export default routes;
