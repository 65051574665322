import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const getPerformanceRecords = (year) => async (dispatch) => {
    dispatch(loader(true));
    try {
        firebase
            .firestore()
            .collection("performance")
            .orderBy("createdAt", "desc")
            .where("year", "==", year)
            .onSnapshot((query) => {
                let data = [];
                for (let doc of query.docs) {
                    if (doc.exists) {
                        data.push({ id: doc.id, ...doc.data() });
                    }
                }
                dispatch({
                    type: "RECORDS",
                    payload: data,
                });
                dispatch(loader(false));
            });
    } catch (error) {
        dispatch(loader(false));
        console.error("Error Getting Records:", error);
    }
};
export const getAllYears = () => async (dispatch) => {
    try {
        firebase
            .firestore()
            .collection("performance")
            .onSnapshot((query) => {
                let years = [];
                for (let doc of query.docs) {
                    if (doc.exists) {
                        const year = doc.data().year;
                        if (year && !years.includes(year)) {
                            years.push(year);
                        }
                    }
                }
                dispatch({
                    type: "ALL_YEARS",
                    payload: years,
                });
            });
    } catch (error) {
        console.error("Error Getting years:", error);
    }
};

export const getKeyFactorsAction = () => async (dispatch) => {
    dispatch(factorsLoader(true));
    try {
        firebase
            .firestore()
            .collection("performanceKeyFactors")
            .orderBy("createdAt", "desc")
            .limit(10)
            .onSnapshot((query) => {
                let data = [];
                for (let doc of query.docs) {
                    if (doc.exists) {
                        data.push({ id: doc.id, ...doc.data() });
                    }
                }
                dispatch({
                    type: "FACTORS",
                    payload: data,
                });
                dispatch(factorsLoader(false));
            });
    } catch (error) {
        dispatch(factorsLoader(false));
        console.error("Error Getting FACTORS:", error);
    }
};
export const getArticlesAction = () => async (dispatch) => {
    dispatch(articleLoader(true));
    try {
        firebase
            .firestore()
            .collection("articles")
            .orderBy("createdAt", "desc")
            .limit(10)
            .onSnapshot((query) => {
                let data = [];
                for (let doc of query.docs) {
                    if (doc.exists) {
                        data.push({ id: doc.id, ...doc.data() });
                    }
                }
                dispatch({
                    type: "ARTICLES",
                    payload: data,
                });
                dispatch(articleLoader(false));
            });
    } catch (error) {
        dispatch(articleLoader(false));
        console.error("Error Getting ARTICLES:", error);
    }
};
export const getSingleArticle = (id) => async (dispatch) => {
    dispatch(articleLoader(true));
    try {
        firebase
            .firestore()
            .collection("articles")
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    dispatch({
                        type: "SINGLE_ARTICLE",
                        payload: { id: doc.id, ...doc.data() },
                    });
                }

                dispatch(articleLoader(false));
            });
    } catch (error) {
        dispatch(articleLoader(false));
        console.error("Error Getting ARTICLES:", error);
    }
};

export const subscribeNewsLetter = (email, callback) => async (dispatch) => {
    try {
        const docRef = firebase.firestore().collection("newsLetter");

        docRef
            .where("email", "==", email)
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().status === "subscribed") {
                            return;
                        }
                        doc.ref.update({ status: "subscribed" });
                    });
                } else {
                    docRef.add({
                        email: email,
                        status: "subscribed",
                        createdAt: new Date(),
                    });
                }
            })
            .then(() => {
                callback();
            });
    } catch (error) {
        console.error("Error Subscribing:", error);
    }
};

export const sendFormData = (payload, callback) => async (dispatch) => {
    dispatch(loader(true));
    try {
        const response = await fetch(
            "https://us-central1-kinvestment-a6fa9.cloudfunctions.net/app/send-email",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            }
        );

        if (response.ok) {
            const responseData = await response.json();
            if (responseData.success) {
                callback();
            } else {
                toast.error(responseData.message);
            }
            dispatch(loader(false));
        } else {
            dispatch(loader(false));
            toast.error(response.statusText);
        }
    } catch (error) {
        dispatch(loader(false));
        console.log(error);
    }
};

export const loader = (val) => async (dispatch) => {
    dispatch({
        type: "PERFORMANCE_LOADER",
        payload: val,
    });
};
export const articleLoader = (val) => async (dispatch) => {
    dispatch({
        type: "ARTICLE_LOADER",
        payload: val,
    });
};
export const factorsLoader = (val) => async (dispatch) => {
    dispatch({
        type: "FACTORS_LOADER",
        payload: val,
    });
};
