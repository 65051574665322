/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleArticle } from "../store/actions/PerformanceAction";
function SingleArticle() {
    const dispatch = useDispatch();
    const { singleArticleData } = useSelector((state) => state.performance);
    const params = useParams();
    const { id } = params;

    useEffect(() => {
        if (id) {
            dispatch(getSingleArticle(id));
        }
    }, [id]);

    function convertTimestampToDate(timestamp) {
        if (!timestamp || !timestamp.seconds) return null;
        const milliseconds =
            timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000;
        const date = new Date(milliseconds);
        return date.toLocaleString();
    }

    return (
        <>
            <article className="o-hero-internal u-background--dk-blue">
                <section className="o-hero-internal__intro u-reversed-out u-reversed-out--blue u-padding--zero--bottom u-spacing-negative--triple--bottom">
                    <div className="l-container l-wrap l-container--l">
                        <div
                            className="l-article-header u-justify-content--space-between"
                            bp="grid vertical-center gap-none"
                        >
                            <section
                                className="l-article-header__heading u-padding--triple--bottom"
                                bp="6@md"
                            >
                                <span className="u-eyebrow u-font u-eyebrow__posts u-font--l u-spacing--bottom">
                                    <span className="u-eyebrow__text">
                                        Article
                                    </span>
                                    <span className="o-icon">
                                        <svg
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 60 3"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M0 1.5c0 .5523.4477 1 1 1s1-.4477 1-1-.4477-1-1-1-1 .4477-1 1Zm4 0c0 .5523.4477 1 1 1s1-.4477 1-1-.4477-1-1-1-1 .4477-1 1Zm6 .4774h50v-1H10v1Z"
                                                fill="#000"
                                            ></path>
                                        </svg>
                                    </span>
                                </span>

                                <h1 className="o-heading--xl l-container--xs">
                                    {singleArticleData?.title}
                                </h1>

                                <p className="u-spacing--and-half--top u-font--m">
                                    {convertTimestampToDate(
                                        singleArticleData?.createdAt
                                    )}
                                </p>
                            </section>

                            <figure
                                className="l-article-header__figure"
                                bp="6@md"
                            >
                                <picture>
                                    <img
                                        src={singleArticleData?.thumbnail}
                                        alt=""
                                    />
                                </picture>
                            </figure>
                        </div>{" "}
                    </div>
                </section>
            </article>
            <article className="l-article o-background-cover u-background--light u-padding--triple--top">
                <section className="u-spacing--quad--top u-spacing--quad--bottom">
                    <div className="l-container l-container--l l-wrap">
                        <div className="o-rte-text">
                            <p>
                                <strong>{singleArticleData?.title}</strong>
                            </p>
                            <p>{singleArticleData?.description}</p>
                            <p>
                                {convertTimestampToDate(
                                    singleArticleData?.createdAt
                                )}
                            </p>
                        </div>
                    </div>
                </section>
            </article>
        </>
    );
}

export default SingleArticle;
