import React from "react";
import ServicesCard from "./Services/ServicesCard";
import { newOpportunitiesData } from "../DummyData/Common";
import { Col, Row } from "reactstrap";

const Oppurtunities = () => {
    return (
        <div className="my-3">
            {newOpportunitiesData?.map((item) => {
                return (
                    <>
                        <h3 className="text-uppercase pb-5 text-center regular">
                            <strong className="regular">
                                {item.sectionTitle}
                            </strong>
                        </h3>
                        <Row className="my-4 mx-auto justify-content-center">
                            {item?.featuredFunds?.map((item) => {
                                return (
                                    <Col
                                        xs={12}
                                        sm={8}
                                        md={6}
                                        lg={4}
                                        className="my-4 d-flex justify-content-center"
                                        key={item.title}
                                    >
                                        <ServicesCard item={item} />
                                    </Col>
                                );
                            })}
                        </Row>
                    </>
                );
            })}
        </div>
    );
};

export default Oppurtunities;
