import React from "react";
import ComponentHeader from "../components/ComponentHeader";

function GlobalPrivacyPolicy() {
    return (
        <>
            <ComponentHeader title={"Privacy Policy"} />
            <article className="o-section o-background-cover u-background--bluegray-1 py-5">
                <section className="o-block u-spacing--quad--top u-spacing--quad--bottom">
                    <div className="l-container l-container--m l-wrap">
                        <div className="o-rte-text">
                            <h1>Privacy Policy</h1>
                            <h2>Introduction</h2>
                            <p>
                                238investments ("we," "us," "our") is committed
                                to protecting the privacy of our clients,
                                partners, and visitors. This Privacy Policy
                                outlines how we collect, use, disclose, and
                                protect your personal information. By using our
                                website or services, you agree to the terms of
                                this Privacy Policy.
                            </p>
                            <h2>Information We Collect</h2>
                            <h3>Personal Information</h3>
                            <p>
                                We may collect personal information that you
                                provide to us directly, such as:
                            </p>
                            <ul>
                                <li>Name</li>
                                <li>Email address</li>
                                <li>Phone number</li>
                                <li>Address</li>
                                <li>Financial information</li>
                                <li>
                                    Any other information you choose to provide
                                </li>
                            </ul>
                            <h3>Non-Personal Information</h3>
                            <p>
                                We may also collect non-personal information
                                automatically when you visit our website, such
                                as:
                            </p>
                            <ul>
                                <li>IP address</li>
                                <li>Browser type</li>
                                <li>Operating system</li>
                                <li>Pages visited on our site</li>
                                <li>Date and time of your visit</li>
                                <li>Referring website</li>
                            </ul>
                            <h2>How We Use Your Information</h2>
                            <p>
                                We may use your information for the following
                                purposes:
                            </p>
                            <ul>
                                <li>To provide and improve our services</li>
                                <li>
                                    To communicate with you about our services,
                                    updates, and promotions
                                </li>
                                <li>
                                    To process transactions and manage accounts
                                </li>
                                <li>
                                    To comply with legal and regulatory
                                    requirements
                                </li>
                                <li>
                                    To enhance the security and functionality of
                                    our website
                                </li>
                                <li>
                                    To analyze website usage and improve user
                                    experience
                                </li>
                            </ul>
                            <h2>Sharing Your Information</h2>
                            <p>
                                We do not sell, trade, or otherwise transfer
                                your personal information to outside parties
                                except as described below:
                            </p>
                            <ul>
                                <li>
                                    Service Providers: We may share your
                                    information with third-party service
                                    providers who assist us in operating our
                                    business and providing our services.
                                </li>
                                <li>
                                    Legal Requirements: We may disclose your
                                    information if required to do so by law or
                                    in response to valid requests by public
                                    authorities (e.g., a court or government
                                    agency).
                                </li>
                                <li>
                                    Business Transfers: In the event of a
                                    merger, acquisition, or sale of all or a
                                    portion of our assets, your personal
                                    information may be transferred to the
                                    acquiring entity.
                                </li>
                            </ul>
                            <h2>Data Security</h2>
                            <p>
                                We implement a variety of security measures to
                                protect your personal information from
                                unauthorized access, use, or disclosure. These
                                measures include physical, electronic, and
                                managerial procedures to safeguard and secure
                                the information we collect.
                            </p>
                            <h2>Cookies and Tracking Technologies</h2>
                            <p>
                                Our website may use cookies and similar tracking
                                technologies to enhance your experience. You can
                                choose to disable cookies through your browser
                                settings, but this may affect the functionality
                                of our website.
                            </p>
                            <h2>Your Rights</h2>
                            <p>You have the right to:</p>
                            <ul>
                                <li>
                                    Access the personal information we hold
                                    about you
                                </li>
                                <li>
                                    Request correction or deletion of your
                                    personal information
                                </li>
                                <li>
                                    Object to the processing of your personal
                                    information
                                </li>
                                <li>
                                    Request the restriction of processing of
                                    your personal information
                                </li>
                                <li>
                                    Withdraw your consent at any time, where
                                    processing is based on consent
                                </li>
                            </ul>
                            <p>
                                To exercise any of these rights, please contact
                                us using the information provided below.
                            </p>
                            <h2>Changes to This Privacy Policy</h2>
                            <p>
                                We may update this Privacy Policy from time to
                                time. We will notify you of any changes by
                                posting the new Privacy Policy on our website.
                                You are advised to review this Privacy Policy
                                periodically for any changes.
                            </p>
                        </div>
                    </div>
                </section>
            </article>
        </>
    );
}

export default GlobalPrivacyPolicy;
