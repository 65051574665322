/* eslint-disable eqeqeq */
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Container, Row } from "reactstrap";

import { testimonialsData } from "../DummyData/Common";

const Testimonials = () => {
    return (
        <Container className="testimonials">
            {testimonialsData?.map((item, index) => {
                return (
                    <Row
                        className={`d-flex py-5 ${
                            index % 2 == 1 ? "flex-row-reverse" : ""
                        }`}
                        key={index}
                    >
                        {/* <Col
                            md="12"
                            lg="6"
                            xl="6"
                            className={`d-flex align-items-center justify-content-center  ${
                                index % 2 == 0 ? "flex-row-reverse" : ""
                            }`}
                        >
                            <img
                                className={`image1 w-50  rounded ${
                                    index % 2 == 0 ? "ml-4" : "mr-4"
                                }`}
                                src={item?.majorImg}
                                alt=""
                            />
                            <img
                                className="image2 rounded w-25"
                                src={item?.minorImg}
                                alt=""
                            />
                        </Col> */}
                        <Col md="12" lg="6" xl="6" className="mt-4 mt-lg-0">
                            <h1
                                className="testimonials__heading regular"
                                data-aos="fade-right"
                            >
                                {item?.heading}
                            </h1>
                            <p
                                className="testimonials__tag-line lightFont"
                                data-aos="fade-left"
                            >
                                {item?.content}
                            </p>
                            <Link
                                to="/contact"
                                className="o-button u-scroll-animation o-button--primary u-spacing--top u-scroll-animate "
                            >
                                {item?.btnText}
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </Link>
                        </Col>
                    </Row>
                );
            })}
        </Container>
    );
};

export default Testimonials;
