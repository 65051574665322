import HeroSection from "../components/HeroSection";

const AdminView = (props) => {
    return (
        <div>
            <HeroSection />
        </div>
    );
};

export default AdminView;
