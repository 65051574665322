import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ServicesCard = ({ item }) => {
    return (
        <>
            <article class="services__card">
                {/* <div class="services__card-img">
                    <div class="services__card-imgs pv delete">
                        <img src={item?.imgSrc} alt="" className="rounded" />
                    </div>
                </div> */}

                <div class="project-info">
                    <div class="project-title regular">{item.title}</div>

                    <span class="lighter ">{item.content}</span>
                    <span class="tag text-center">
                        <Link
                            to={`${item.btnLink}`}
                            className="o-button u-scroll-animation o-button--primary-ghost u-scroll-animate navbar_btn"
                        >
                            Contact Us
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>
                    </span>
                </div>
            </article>
        </>
    );
};

export default ServicesCard;
