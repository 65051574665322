import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Sidebar from "../components/Sidebar";

const Main = (props) => {
    const location = useLocation();
    const [menu, setMenu] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        setMenu(false);
    }, [location.pathname]);

    const toggleMenu = () => {
        setMenu(!menu);
    };
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setMenu(false);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="app">
            <div className={menu ? "overlay show" : "overlay"}>
                <Sidebar toggleMenu={toggleMenu} />
            </div>
            <NavBar toggleMenu={toggleMenu} />
            <div>{props.children}</div>
            <Footer />
        </div>
    );
};

export default Main;
