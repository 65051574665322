import React from "react";
import { Container } from "reactstrap";
import arrow from "../assets/img/svgs/downarrorw.svg";
import Testimonials from "./Testimonials";

const HeroSection = () => {
    return (
        <div className="hero_section-top">
            <Container fluid className="py-5 ">
                <div className="hero_section py-4 text-center">
                    <h1 className="heading regular">
                        WE ARE IN THE BUSINESS OF CREATIVITY
                    </h1>
                    <div className="mx-auto para_div">
                        <p className="paragraph lightFont">
                            At 238kinvestments, we invest in creativity — but
                            novel ideas don’t manifest on their own. We empower
                            people to develop their skills, challenge the status
                            quo, and turn their innovative concepts into
                            reality.
                        </p>
                    </div>
                    <img src={arrow} alt="" className="my-5 mx-auto" />
                </div>
                <Testimonials />
            </Container>
        </div>
    );
};

export default HeroSection;
