import React from "react";

const ComponentHeader = ({ title, subTitle }) => {
    return (
        <>
            <article className="o-hero-internal o-background-cover u-background--dk-blue">
                <section className="o-hero-internal__intro u-reversed-out u-reversed-out--blue">
                    <div className="l-container l-wrap">
                        <h2 className="u-eyebrow u-font u-font--l u-scroll-animation scale-in-bottom u-scroll-animate">
                            <span className="u-eyebrow__text">
                                238k Investment
                            </span>
                            <span className="o-icon">
                                <svg
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 60 3"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0 1.5c0 .5523.4477 1 1 1s1-.4477 1-1-.4477-1-1-1-1 .4477-1 1Zm4 0c0 .5523.4477 1 1 1s1-.4477 1-1-.4477-1-1-1-1 .4477-1 1Zm6 .4774h50v-1H10v1Z"
                                        fill="#000"
                                    ></path>
                                </svg>
                            </span>
                        </h2>{" "}
                        <h1 className="o-heading--xxl u-text-transform--upper u-spacing--top u-scroll-animation scale-in-bottom u-scroll-animation--delay-two u-scroll-animate">
                            {" "}
                            {title}
                        </h1>
                        {subTitle && (
                            <div className="o-rte-text o-rte-text-l u-spacing--and-half--top l-container--l u-spacing--zero--left u-spacing--zero--right u-scroll-animation scale-in-bottom u-scroll-animation--delay-three u-scroll-animate">
                                <div className="o-rte-text o-rte-text-l u-spacing--and-half--top l-container--l u-spacing--zero--left u-spacing--zero--right">
                                    <p className="lightFont">{subTitle}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </article>
        </>
    );
};

export default ComponentHeader;
