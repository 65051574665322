const initState = {
    performanceLoading: false,
    performanceRecords: [],
    factorsData: [],
    articlesData: [],
};
const performanceReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case "PERFORMANCE_LOADER":
            return {
                ...state,
                performanceLoading: payload,
            };
        case "ARTICLE_LOADER":
            return {
                ...state,
                articlesLoading: payload,
            };
        case "FACTORS_LOADER":
            return {
                ...state,
                factorsLoading: payload,
            };
        case "RECORDS":
            return {
                ...state,
                performanceRecords: payload,
            };
        case "ALL_YEARS":
            return {
                ...state,
                allYearsData: payload,
            };
        case "FACTORS":
            return {
                ...state,
                factorsData: payload,
            };
        case "ARTICLES":
            return {
                ...state,
                articlesData: payload,
            };
        case "SINGLE_ARTICLE":
            return {
                ...state,
                singleArticleData: payload,
            };
        default:
            return {
                ...state,
            };
    }
};
export default performanceReducer;
